<template>
  <div id="app" class="row">
    <div class="col-md-4">
      <form asp-action="Edit">
        <div asp-validation-summary="ModelOnly" class="text-danger"></div>
        <input type="hidden" asp-for="Id" />
        <div class="form-group">
          <table
            id="mainTable"
            class="table table-striped table-td-p0"
            style="cursor: pointer"
          >
            <thead>
              <tr>
                <th></th>
                <th v-for="(header, xIndex) in headers" :key="xIndex">
                  <v-select
                    label="value"
                    :reduce="(xKey) => xKey.key"
                    :options="xKeys"
                    v-model="header.key"
                    :clearable="false"
                    :selectOnTab="true"
                  >
                  </v-select>
                  <a v-on:click="delColumn(xIndex)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </a>
                </th>
                <th style="min-width: 100px">
                  <b-button variant="success" size="sm" v-on:click="addColumn"
                    >添加颜色</b-button
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, yIndex) in rows" :key="yIndex">
                <td>
                  <select v-model="row.key">
                    <option
                      v-for="yKey in yKeys"
                      :value="yKey.key"
                      :key="yKey.key"
                    >
                      {{ yKey.value }}
                    </option>
                  </select>
                  <a v-on:click="delRow(yIndex)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </a>
                </td>
                <td v-for="(cell, xIndex) in data[yIndex]" :key="xIndex">
                  <input
                    type="number"
                    v-model.number="data[yIndex][xIndex].count"
                    style="width: 60px"
                    @focus="$event.target.select()"
                  />
                </td>
              </tr>
              <tr>
                <td style="min-width: 100px">
                  <b-button variant="success" size="sm" v-on:click="addRow"
                    >添加尺码</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ProductionService from '../services/production.service'

export default {
  // props: ['data', 'colors', 'sizes'],
  props: ['details'],
  data() {
    return {
      headers: [],
      rows: [],
      data: [],
      dataDelete: [],
      xKeys: [
        { key: 'xkey1', value: 'xvalue1' },
        { key: 'xkey2', value: 'xvalue2' },
        { key: 'xkey3', value: 'xvalue3' }
      ],
      yKeys: [
        { key: 'ykey1', value: 'yvalue1' },
        { key: 'ykey2', value: 'yvalue2' },
        { key: 'ykey3', value: 'yvalue3' }
      ]
    }
  },
  created: function () {
    // var pnJson = JSON.parse('@pn')
    var pnJson = this.$props.details
    this.xKeys = pnJson.xKeys
    this.yKeys = pnJson.yKeys
    this.data = pnJson.values
    this.dataDelete = []
    this.headers = (pnJson.headers || []).map((s) => {
      return { key: s }
    })
    this.rows = (pnJson.rowers || []).map((s) => {
      return { key: s }
    })
    // console.log(pnJson)
    // // GET request for remote image in node.js
    // axios({
    //     method: 'get',
    //     url: '/my/Procedures',
    //     responseType: 'json'
    // })
    //     .then(function (response) {
    //         this.employee = response.data.employee;
    //         this.unSettledSalary = response.data.unSettledSalary;
    //         this.procedures = response.data.procedures;
    //     });
  },
  methods: {
    addColumn: function (params) {
      this.headers.push({})
      for (let yIndex = 0; yIndex < this.data.length; yIndex++) {
        this.data[yIndex].push({ count: 0 })
      }
    },
    addRow: function (params) {
      this.rows.push({})
      var columnCount = this.headers.length
      var cells = []
      for (let cIndex = 0; cIndex < columnCount; cIndex++) {
        cells.push({ count: 0 })
      }
      this.data.push(cells)
    },
    async delColumn(index) {
      var columnData = []
      for (var yIndex = 0; yIndex < this.rows.length; yIndex++) {
        columnData.push(this.data[yIndex][index])
      }

      try {
        const response = await ProductionService.canDelete(
          this.$props.details.id,
          columnData
        )
        if (response.data === true) {
          for (var i = 0; i < this.rows.length; i++) {
            // 加入删除列表
            var v = this.data[i][index]
            if (v && v.id) {
              this.dataDelete.push(v)
            }
            this.data[i].splice(index, 1)
          }
          this.headers.splice(index, 1)
        } else if (response.data === false) {
          window.alert('有工序已经被扫描，不能删除')
        }
      } catch {}
    },
    async delRow(index) {
      try {
        const response = await ProductionService.canDelete(
          this.$props.details.id,
          this.data[index]
        )
        if (response.data === true) {
          // 加入删除列表
          for (let i = 0; i < this.data[index].length; i++) {
            const v = this.data[index][i]
            if (v && v.id) {
              this.dataDelete.push(v)
            }
          }
          this.data.splice(index, 1)
          this.rows.splice(index, 1)
        } else if (response.data === false) {
          window.alert('有工序已经被扫描，不能删除')
        }
      } catch {}
    },
    async fullValidEvent() {
      // console.log(this.data)
      // 检查选单是否都有选
      var allSelected = true
      this.headers.forEach(function (h) {
        if (h.key === undefined) {
          allSelected = false
        }
      })
      this.rows.forEach(function (r) {
        if (r.key === undefined) {
          allSelected = false
        }
      })
      if (!allSelected) {
        window.alert('还有尺码或颜色没有选择规格，请选择之后再保存。')
        return false
      }
      return true
    },

    getData() {
      const records = {
        xKeys: this.xKeys,
        yKeys: this.yKeys,
        values: this.data,
        headers: this.headers.map((h) => h.key),
        rowers: this.rows.map((r) => r.key),
        valuesDelete: this.dataDelete
      }
      return records
    }
  }
}
</script>

<style scoped>
.first-col {
  position: relative;
  height: 20px;
}

.first-col:before {
  content: '';
  position: absolute;
  left: -15px;
  top: 10px;
  width: 110px;
  height: 1px;
  transform: rotate(20deg);
  background-color: #e8eaec;
}

.first-col .first-col-top {
  position: absolute;
  right: 4px;
  top: -10px;
}

.first-col .first-col-bottom {
  position: absolute;
  left: 4px;
  bottom: -10px;
}

.table-td-p0 td,
.table-td-p0 th {
  padding: 0px;
}
</style>
