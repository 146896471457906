import api from '../services/api'

class UserProcedureService {
  details(id) {
    return api.get(`/ep/${id}`)
  }

  delete(id) {
    return api.delete(`/ep/${id}`)
  }
}

export default new UserProcedureService()
