import api from '../services/api'
import Helper from './helper'

class ProductionService {
  get(p, s) {
    return api.get(`/pn?${Helper.getPageQuery(p, s)}`)
  }

  getEditOne(id) {
    return api.get('/pn/edit/' + id)
  }

  create(row) {
    return api.post('pn', row)
  }

  update(row) {
    return api.post('/pn/edit/' + row.id, row)
  }

  details(id) {
    return api.get('pn/' + id)
  }

  status(id) {
    return api.get('pn/s/' + id)
  }

  canDelete(id) {
    return api.post('/pn/canDelete/' + id)
  }
}

export default new ProductionService()
