var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"model-process","size":"xl","dialog-class":"printTest","hide-footer":true},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('h4',[_vm._v(" "+_vm._s(_vm.process.title)+" "),_c('span',[_vm._v(" 每页打印")]),_c('vxe-select',{staticStyle:{"width":"50px"},model:{value:(_vm.pageBreak),callback:function ($$v) {_vm.pageBreak=$$v},expression:"pageBreak"}},_vm._l((5),function(i){return _c('vxe-option',{key:i,attrs:{"value":i,"label":i}})}),1),_c('span',[_vm._v("筒 ")]),_c('button',{directives:[{name:"print",rawName:"v-print",value:('#printTest'),expression:"'#printTest'"}]},[_vm._v("打印")])],1),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return close()}}},[_vm._v(" 关闭 ")])]}}])},[_c('div',{attrs:{"id":"printTest"}},_vm._l((_vm.process.details),function(row,dIndex){return _c('div',{key:dIndex},[_c('b-card',{staticClass:"text-center mb-3"},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticStyle:{"width":"120px","margin-right":"30px"}},[_vm._l((row.properties),function(p,pIndex){return _c('div',{key:pIndex},[_vm._v(" "+_vm._s(p)+" ")])}),_c('hr'),_c('div',[_c('vue-qrcode',{attrs:{"margin":2,"scale":2,"value":(_vm.backendUrl + "/Psh/Create?pd=" + (row.productionDetailId))}})],1)],2),_c('b-card-text',[_c('b-card-group',{attrs:{"deck":""}},_vm._l((row.procecures),function(procedure,gIndex){return _c('b-card',{key:gIndex,attrs:{"bg-variant":procedure.statusColor,"body-class":"p-1"}},[_c('div',{staticClass:"d-flex flex-row",on:{"click":function($event){return _vm.showDetails(procedure)}}},[_c('vue-qrcode',{attrs:{"margin":2,"scale":2,"value":_vm.backendUrl + "/Ep/Create?pd=" +
                        procedure.productionDetailId +
                        "&pp=" +
                        procedure.productProcedureId}}),_c('div',{staticClass:"ml-2 text-left"},[_c('div',[_c('h5',[_vm._v(" "+_vm._s(procedure.procedure)+" ")])]),_c('div',[_c('small',[_vm._v(_vm._s(_vm.process.product))])]),_c('div',[_c('small',{staticClass:"font-weight-light"},[_vm._v("第"+_vm._s(procedure.identifier)+"筒 ")]),_c('a',{staticClass:"d-none",attrs:{"href":_vm.backendUrl + "/Ep/Create?pd=" +
                            procedure.productionDetailId +
                            "&pp=" +
                            procedure.productProcedureId}},[_vm._v("ep")])]),_c('div',[_c('b-card-text',[_c('small',[_vm._v(_vm._s(procedure.count)+"件 "+_vm._s(procedure.color)+" "+_vm._s(procedure.size)+" ")])])],1)])],1)])}),1)],1)],1)]),(dIndex % _vm.pageBreak == _vm.pageBreak - 1)?_c('div',{staticClass:"newPage"}):_vm._e()],1)}),0)]),_c('b-modal',{attrs:{"id":"modal-multi-3","size":"sm","title":"工序信息","ok-only":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
                            var hide = ref.hide;
return [_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.deleteProcedure(_vm.procedureDetails.id)}}},[_vm._v(" 删除 ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return hide('forget')}}},[_vm._v(" 返回 ")])]}}])},[_c('p',{staticClass:"my-1"},[_vm._v(" "+_vm._s(_vm.procedureDetails.employee)+" 扫描于 "+_vm._s(_vm.procedureDetails.created)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }