<template>
  <div>
    <b-modal
      id="model-process"
      size="xl"
      dialog-class="printTest"
      :hide-footer="true"
    >
      <template #modal-header="{ close }">
        <h4>
          {{ process.title }}
          <span> 每页打印</span>
          <vxe-select v-model="pageBreak" style="width: 50px">
            <vxe-option
              v-for="i in 5"
              :key="i"
              :value="i"
              :label="i"
            ></vxe-option>
          </vxe-select>
          <span>筒 </span><button v-print="'#printTest'">打印</button>
        </h4>
        <!-- <b-button size="sm" @click="checkProcess">检查</b-button> -->
        <b-button size="sm" @click="close()"> 关闭 </b-button>
      </template>
      <div id="printTest">
        <div v-for="(row, dIndex) in process.details" :key="dIndex">
          <b-card class="text-center mb-3">
            <div class="d-flex flex-row">
              <div style="width: 120px; margin-right: 30px">
                <div v-for="(p, pIndex) in row.properties" :key="pIndex">
                  {{ p }}
                </div>
                <hr />
                <div>
                  <vue-qrcode
                    :margin="2"
                    :scale="2"
                    :value="`${backendUrl}/Psh/Create?pd=${row.productionDetailId}`"
                  />
                </div>
              </div>
              <b-card-text>
                <b-card-group deck>
                  <b-card
                    v-for="(procedure, gIndex) in row.procecures"
                    :key="gIndex"
                    :bg-variant="procedure.statusColor"
                    body-class="p-1"
                  >
                    <div
                      class="d-flex flex-row"
                      @click="showDetails(procedure)"
                    >
                      <vue-qrcode
                        :margin="2"
                        :scale="2"
                        :value="
                          `${backendUrl}/Ep/Create?pd=` +
                          procedure.productionDetailId +
                          `&pp=` +
                          procedure.productProcedureId
                        "
                      />
                      <div class="ml-2 text-left">
                        <div>
                          <h5>
                            {{ procedure.procedure }}
                          </h5>
                        </div>
                        <div>
                          <small>{{ process.product }}</small>
                        </div>
                        <div>
                          <small class="font-weight-light"
                            >第{{ procedure.identifier }}筒
                          </small>
                          <a
                            class="d-none"
                            :href="
                              `${backendUrl}/Ep/Create?pd=` +
                              procedure.productionDetailId +
                              `&pp=` +
                              procedure.productProcedureId
                            "
                            >ep</a
                          >
                        </div>
                        <div>
                          <b-card-text>
                            <small
                              >{{ procedure.count }}件 {{ procedure.color }}
                              {{ procedure.size }}
                            </small>
                          </b-card-text>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-card-group>
              </b-card-text>
            </div>
          </b-card>
          <div v-if="dIndex % pageBreak == pageBreak - 1" class="newPage"></div>
        </div></div
    ></b-modal>
    <b-modal id="modal-multi-3" size="sm" title="工序信息" ok-only>
      <p class="my-1">
        {{ procedureDetails.employee }} 扫描于 {{ procedureDetails.created }}
      </p>
      <template #modal-footer="{ hide }">
        <b-button
          size="sm"
          variant="danger"
          @click="deleteProcedure(procedureDetails.id)"
        >
          删除
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          返回
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ProductionService from '../services/production.service'
import userProcedureService from '../services/user-procedure.service'
import VueQrcode from 'vue-qrcode'

export default {
  props: [],
  components: {
    VueQrcode
  },
  data() {
    return {
      checkProcessTimer: '',
      process: {
        details: []
      },
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      pageBreak: 4,
      procedureDetails: {}
    }
  },
  created() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'model-process') {
        clearInterval(this.checkProcessTimer)
      }
    })
  },
  methods: {
    async showModal(id) {
      this.$bvModal.show('model-process')
      try {
        const response = await ProductionService.details(id)
        this.process = response.data
        this.checkProcessTimer = setInterval(this.checkProcess, 3000)
      } catch {}
    },
    async checkProcess() {
      const productionId = this.process.id
      try {
        const response = await ProductionService.status(productionId)
        this.process.details.forEach((detail, index, arr) => {
          response.data.forEach((status) => {
            const dIndex = detail.procecures.findIndex(
              (d) =>
                d.productProcedureId === status.pp &&
                d.productionDetailId === status.pd
            )
            if (dIndex >= 0) {
              arr[index].procecures[dIndex].id = status.id
              arr[index].procecures[dIndex].color += ' '
              arr[index].procecures[dIndex].statusColor = status.statusColor
            } else {
            }
          })
        })
      } catch {}
    },
    async showDetails(procedure) {
      if (procedure.id === undefined) {
        return
      }
      this.$bvModal.show('modal-multi-3')
      await this.details(procedure.id)
    },
    hideDetails() {
      this.$bvModal.hide('modal-multi-3')
    },
    async details(id) {
      try {
        this.procedureDetails = {}
        const response = await userProcedureService.details(id)
        this.procedureDetails = response.data
      } catch {}
    },
    deleteProcedure(id) {
      this.$bvModal
        .msgBoxConfirm('删除后无法恢复，确定要删除吗？', {
          title: '警告',
          size: 'sm',
          buttonSize: 'sm'
        })
        .then(async (value) => {
          if (value) {
            try {
              await userProcedureService.delete(id)

              this.process.details.forEach((detail, index, arr) => {
                const dIndex = detail.procecures.findIndex((d) => d.id === id)
                if (dIndex >= 0) {
                  this.$delete(arr[index].procecures[dIndex], 'id')
                  this.$delete(arr[index].procecures[dIndex], 'statusColor')
                }
              })

              this.hideDetails()
            } catch {}
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err)
        })
    }
  }
}
</script>

<style scoped>
/deep/ .newPage {
  page-break-after: always;
}
/deep/ .card-deck .card {
  width: 220px;
  flex: none;
  margin-right: 0;
  margin-left: 0;
}

/deep/ .card-deck .card-body {
  padding: 0.75 rem;
}

@media print {
  /deep/ .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}
</style>
