<template>
  <div>
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button icon="fa fa-plus" @click="insertEvent">新增</vxe-button>
      </template>
    </vxe-toolbar>

    <vxe-table
      border
      resizable
      row-key
      show-overflow
      highlight-hover-row
      ref="xTable"
      :data="tableData.rows"
      :loading="loading"
    >
      <vxe-table-column
        field="number"
        title="版号"
        width="60"
      ></vxe-table-column>
      <vxe-table-column field="product" title="产品名称"></vxe-table-column>
      <vxe-table-column
        field="created"
        title="创建时间"
        formatter="dateTime"
      ></vxe-table-column>
      <vxe-table-column title="筒数">
        <template v-slot="{ row }">
          <b-badge variant="light" v-for="p in row.identifiers" :key="p"
            >{{ p }}
          </b-badge>
        </template>
      </vxe-table-column>
      <vxe-table-column
        field="updated"
        title="修改时间"
        formatter="dateTime"
      ></vxe-table-column>
      <vxe-table-column title="操作" width="100" show-overflow>
        <template v-slot="{ row }">
          <vxe-button
            type="text"
            icon="fa fa-edit"
            @click="editEvent(row)"
          ></vxe-button>
          <vxe-button
            type="text"
            icon="fa fa-tasks"
            @click="details(row)"
          ></vxe-button>
          <!-- <vxe-button
            type="text"
            icon="fa fa-trash-o"
            @click="removeEvent(row)"
          ></vxe-button> -->
        </template>
      </vxe-table-column>
    </vxe-table>

    <vxe-pager
      perfect
      :current-page.sync="tableData.page"
      :page-size.sync="tableData.pageSize"
      :total="tableData.totalResult"
      @page-change="pageChange"
    >
    </vxe-pager>

    <vxe-modal
      v-model="showEdit"
      :title="selectRow ? '编辑&保存' : '新增&保存'"
      :loading="submitLoading"
      height="600"
      width="900"
      lock-view
      :lock-scroll="false"
      destroy-on-close
    >
      <template v-slot>
        <vxe-form
          :data="formData"
          :rules="formRules"
          title-align="right"
          title-width="100"
          @submit="submitEvent"
        >
          <vxe-form-item v-bind="formItems[0]"></vxe-form-item>
          <vxe-form-item v-if="formData.id" v-bind="formItems[1]">
            <ProductionDetailJQuery
              ref="procedures"
              :details="formData.details"
              :colors="tableData.colors"
              :sizes="tableData.sizes"
            ></ProductionDetailJQuery>
          </vxe-form-item>
          <vxe-form-item v-bind="formItems[2]"></vxe-form-item>
        </vxe-form>
      </template>
    </vxe-modal>

    <ProductionProcess ref="processModal"></ProductionProcess>
  </div>
</template>

<script>
import ProductionService from '../services/production.service'
import ProductService from '../services/product.service'
import ProductionDetailJQuery from '../components/ProductionDetailJQuery'
import ProductionProcess from '../components/ProductionProcess'

export default {
  components: {
    ProductionDetailJQuery,
    ProductionProcess
  },
  data() {
    return {
      loading: false,
      submitLoading: false,
      tableData: {},
      selectRow: null,
      showEdit: false,
      process: {},
      formData: {
        id: null,
        productId: null,
        products: [],
        details: {}
      },
      formRules: {
        productId: [{ required: true, message: '请选择产品' }]
      },
      formItems: [
        {
          field: 'productId',
          title: '产品名称',
          span: 24,
          itemRender: {
            name: '$select',
            optionProps: { value: 'id', label: 'title' },
            props: { placeholder: '请选择产品' }
          }
        },
        {
          field: 'procedures',
          title: '工序',
          span: 24
        },
        {
          align: 'center',
          span: 24,
          titleAlign: 'left',
          itemRender: {
            name: '$buttons',
            children: [
              { props: { type: 'submit', content: '提交', status: 'primary' } }
            ]
          }
        }
      ],
      productSelectLoaded: false
    }
  },
  async mounted() {
    this.loading = true
    try {
      const response = await ProductionService.get()
      this.tableData = response.data
    } catch {
      this.tableData = {}
    }
    this.loading = false
  },
  methods: {
    setProductSelectDisabled(disabled) {
      this.formItems[0].itemRender.props.disabled = disabled
    },
    async checkProcess() {
      const productionId = this.process.id
      try {
        const response = await ProductionService.status(productionId)
        this.process.details.forEach((detail, index, arr) => {
          response.data.forEach((status) => {
            const dIndex = detail.procecures.findIndex(
              (d) =>
                d.productProcedureId === status.pp &&
                d.productionDetailId === status.pd
            )
            if (dIndex >= 0) {
              arr[index].procecures[dIndex].color += ' '
              arr[index].procecures[dIndex].statusColor = status.statusColor
            } else {
            }
          })
        })
      } catch {}
    },
    async checkProductSelect() {
      if (!this.productSelectLoaded) {
        this.productSelectLoaded = true
        const response = await ProductService.get(1, 30)
        if (
          response.data &&
          response.data.rows &&
          response.data.rows.length > 0
        ) {
          this.formItems[0].itemRender.options = response.data.rows
        }
      }
    },
    async insertEvent() {
      this.setProductSelectDisabled(false)
      await this.checkProductSelect()
      this.formData = {
        productId: ''
      }
      this.selectRow = null
      this.showEdit = true
    },
    async editEvent(row) {
      this.setProductSelectDisabled(true)
      await this.checkProductSelect()
      const response = await ProductionService.getEditOne(row.id)
      this.formData = {
        id: row.id,
        productId: row.productId,
        details: response.data
      }
      this.selectRow = row
      this.showEdit = true
    },
    async details(row) {
      this.$refs.processModal.showModal(row.id)
      // console.log(response.data)
    },
    // removeEvent(row) {
    //   this.$XModal.confirm('您确定要删除该数据?').then(async (type) => {
    //     if (type === 'confirm') {
    //       this.loading = true
    //       const product = new Product()
    //       Object.assign(product, row)
    //       try {
    //         const response = await ProductionService.delete(product)
    //         if (response.data.status === 200) {
    //           this.$refs.xTable.remove(row)
    //         }
    //       } catch {}
    //       this.loading = false
    //     }
    //   })
    // },
    async submitEvent() {
      if (this.selectRow) {
        try {
          if (!(await this.$refs.procedures.fullValidEvent())) {
            return
          }
          this.submitLoading = true
          const production = {
            id: this.formData.id,
            ...this.$refs.procedures.getData()
          }
          const response = await ProductionService.update(production)
          this.showEdit = false
          Object.assign(this.selectRow, response.data.data)
        } catch {}
      } else {
        try {
          const production = {
            productId: this.formData.productId
          }
          production.productId = this.formData.productId
          const response = await ProductionService.create(production)
          this.showEdit = false
          this.$refs.xTable.insert(response.data.data)
        } catch {}
      }
      this.submitLoading = false
    },
    async pageChange({ currentPage, pageSize }) {
      this.loading = true
      try {
        const response = await ProductionService.get(currentPage, pageSize)
        this.tableData = response.data
      } catch {
        this.tableData = {}
      }
      this.loading = false
    }
  }
}
</script>
